<template>
  <b-card title="Listagem">
    <!-- table -->
    <listarTiposProcessos />

  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarTiposProcessos from '../../Listagens/TiposProcessos/ListarTiposProcesso.vue'

export default {
  components: {
    BCard, listarTiposProcessos,

  },

}

</script>
